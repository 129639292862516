// src/components/Header.tsx
import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import PixelPurchase from "./PixelPurchase";

interface HeaderProps {
  selectedPixels: number[];
  onPurchaseComplete: () => void;
}

const Header: React.FC<HeaderProps> = ({
  selectedPixels,
  onPurchaseComplete,
}) => {
  return (
    <header className="w-full p-4 bg-gray-800 flex justify-between items-center">
      <h1 className="text-2xl text-white">BaseLots</h1>
      <div className="flex items-center space-x-4">
        <div className="hidden lg:block">
          <PixelPurchase
            selectedPixels={selectedPixels}
            onPurchaseComplete={onPurchaseComplete}
          />
        </div>
        <ConnectButton />
      </div>
    </header>
  );
};

export default Header;

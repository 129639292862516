// src/components/Footer.tsx
import React from "react";

const Footer = () => {
  return (
    <footer className="w-full p-4 bg-gray-800 text-center">
      <div className="flex justify-center items-center">
        <p>© 2024 BaseLots.xyz</p>
        <a
          href="https://warpcast.com/basedfox"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://basedlots.s3.us-east-2.amazonaws.com/old/images/FCLogo.png"
            alt="BasedFox"
            className="w-10 h-10 ml-2"
          />
        </a>
        <a
          href="https://warpcast.com/basedfox"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="https://basedlots.s3.us-east-2.amazonaws.com/old/images/BaseScanTransparent.svg"
            alt="BaseScan"
            className="w-10 h-10 ml-2"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;

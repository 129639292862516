import React, { useState, useEffect } from "react";
import { Alchemy, Network } from "alchemy-sdk";
import { useAccount } from "wagmi";
import { NFT, ColorTrayProps } from "../types/types";

const ColorTray: React.FC<ColorTrayProps> = ({
  isOpen,
  onClose,
  setSelectedColor,
  selectedColor, // Destructure selectedColor prop
}) => {
  const [nfts, setNfts] = useState<NFT[]>([]);
  const { address } = useAccount();

  useEffect(() => {
    const fetchNFTs = async () => {
      if (!address) return;

      const settings = {
        apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
        network: Network.BASE_MAINNET,
      };
      const alchemy = new Alchemy(settings);
      const contractAddress = "0x7bc1c072742d8391817eb4eb2317f98dc72c61db";

      try {
        const nftsForOwner = await alchemy.nft.getNftsForOwner(address, {
          contractAddresses: [contractAddress],
        });
        const colorNFTs = nftsForOwner.ownedNfts
          .filter((nft) => nft.name !== undefined)
          .map((nft) => ({
            tokenId: nft.tokenId,
            color: nft.name!.startsWith("#")
              ? nft.name!
              : `#${nft.name!.slice(1)}`,
          }));
        setNfts(colorNFTs);
      } catch (error) {
        console.error("Error fetching NFTs:", error);
      }
    };

    if (isOpen && address) {
      fetchNFTs();
    }
  }, [isOpen, address]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-4 rounded-lg">
        <h2 className="text-xl mb-4">Select a Color</h2>
        <div className="grid grid-cols-5 gap-2">
          {nfts.map((nft) => (
            <div
              key={nft.tokenId}
              className={`w-12 h-12 cursor-pointer ${
                selectedColor === nft.color ? "border-4 border-gold" : ""
              }`}
              style={{ backgroundColor: nft.color }}
              onClick={() => {
                setSelectedColor(nft.color);
              }}
            />
          ))}
        </div>
        {nfts.length === 0 && (
          <p className="text-white">No color NFTs found for this address.</p>
        )}
        <button
          className="mt-4 bg-yellow-500 text-black px-4 py-2 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ColorTray;

import React, { useState } from "react";
import ColorTray from "./ColorTray";
import ColorPunkTray from "./ColorPunkTray";
import PixelPurchase from "./PixelPurchase";
import { ColorTrayProps, ColorPunkTrayProps } from "../types/types";

interface ControlsProps {
  zoomIn: () => void;
  zoomOut: () => void;
  zoomLevel: number;
  maxZoomLevel: number;
  clearSelection: () => void;
  saveChanges: () => void;
  selectPreset: (size: number) => void;
  setSelectedColor: (color: string) => void;
  onSelectColorPunk: (tokenId: string, imageUrl: string) => void;
  selectedPixels: number[];
  selectedColor: string;
  showOwnedPixels: boolean;
  is5x5Selected: boolean;
  setShowOwnedPixels: React.Dispatch<React.SetStateAction<boolean>>;
  ownedAndSelectedForUpdates: number[];
  setOwnedAndSelectedForUpdates: React.Dispatch<React.SetStateAction<number[]>>;
  selectedColorPunk: { tokenId: string; imageUrl: string } | null;
  setSelectedColorPunk: React.Dispatch<
    React.SetStateAction<{ tokenId: string; imageUrl: string } | null>
  >;
  onPurchaseComplete: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  zoomIn,
  zoomOut,
  zoomLevel,
  maxZoomLevel,
  clearSelection,
  saveChanges,
  selectPreset,
  setSelectedColor,
  onSelectColorPunk,
  selectedPixels,
  selectedColor,
  is5x5Selected,
  showOwnedPixels,
  setShowOwnedPixels,
  ownedAndSelectedForUpdates,
  setOwnedAndSelectedForUpdates,
  selectedColorPunk,
  setSelectedColorPunk,
  onPurchaseComplete,
}) => {
  console.log("Controls is5x5Selected:", is5x5Selected);
  const [isColorTrayOpen, setIsColorTrayOpen] = useState(false);
  const [isColorPunkTrayOpen, setIsColorPunkTrayOpen] = useState(false);
  const lots = [5, 10];
  const canDropColorPunk = is5x5Selected; // Check if 5x5 owned pixels are selected
  console.log("canDropColorPunk:", canDropColorPunk);
  const canClearSelection =
    selectedPixels.length > 0 || ownedAndSelectedForUpdates.length > 0;
  const canColorPixels = ownedAndSelectedForUpdates.length > 0;
  const canSaveChanges = ownedAndSelectedForUpdates.length > 0;

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="lg:hidden">
        <PixelPurchase
          selectedPixels={selectedPixels}
          onPurchaseComplete={onPurchaseComplete}
        />
      </div>
      <div className="flex-grow">
        {/* Zoom Controls */}
        {/* <button
          onClick={zoomIn}
          className={`${
            zoomLevel === maxZoomLevel ? "bg-gray-500" : "bg-yellow-500"
          } text-black px-4 py-2 rounded-full w-full mb-2`}
          disabled={zoomLevel === maxZoomLevel}
        >
          Zoom In
        </button>
        <button
          onClick={zoomOut}
          className={`${
            zoomLevel === 0 ? "bg-gray-500" : "bg-yellow-500"
          } text-black px-4 py-2 rounded-full w-full mb-2`}
          disabled={zoomLevel === 0}
        >
          Zoom Out
        </button> */}

        {/* Clear Selection */}
        <button
          onClick={clearSelection}
          className={`${
            canClearSelection
              ? "bg-red-500 text-white"
              : "bg-gray-500 cursor-not-allowed text-black"
          } px-4 py-2 rounded-full w-full mb-4`}
          disabled={!canClearSelection}
        >
          Clear Selection
        </button>

        {/* Save Changes */}
        <button
          onClick={saveChanges}
          className={`${
            canSaveChanges ? "bg-blue-500" : "bg-gray-500 cursor-not-allowed"
          } text-white px-4 py-2 rounded-full w-full mb-4`}
          disabled={!canSaveChanges}
        >
          Save Changes
        </button>

        {/* Lots */}
        <div className="flex flex-col gap-2 my-4">
          <h3 className="text-lg font-bold">Lots</h3>
          {lots.map((size) => (
            <button
              key={size}
              onClick={() => {
                selectPreset(size);
              }}
              className="bg-blue-500 text-white px-4 py-2 rounded-full w-full"
            >
              {size}x{size}
            </button>
          ))}
        </div>
      </div>

      {/* Pixel Actions */}
      <div className="mt-auto">
        <button
          onClick={() => setIsColorTrayOpen(!isColorTrayOpen)}
          className={`${
            canColorPixels ? "bg-purple-500" : "bg-gray-500 cursor-not-allowed"
          } text-white px-6 py-2 rounded-full w-full mb-2`}
          disabled={!canColorPixels}
        >
          Color Pixels
        </button>
        <ColorTray
          isOpen={isColorTrayOpen}
          onClose={() => setIsColorTrayOpen(false)}
          setSelectedColor={setSelectedColor}
          selectedColor={selectedColor}
        />
        <button
          onClick={() => setIsColorPunkTrayOpen(!isColorPunkTrayOpen)}
          className={`${
            canDropColorPunk ? "bg-green-500" : "bg-gray-500 cursor-not-allowed"
          } text-white px-6 py-2 rounded-full w-full`}
          disabled={!canDropColorPunk}
        >
          Drop Color Punk
        </button>
        <ColorPunkTray
          isOpen={isColorPunkTrayOpen}
          onClose={() => setIsColorPunkTrayOpen(false)}
          onSelectPunk={onSelectColorPunk}
          selectedColorPunk={selectedColorPunk}
          setSelectedColorPunk={setSelectedColorPunk}
        />
        <button
          onClick={() => setShowOwnedPixels(!showOwnedPixels)}
          className={`${
            showOwnedPixels ? "bg-yellow-500" : "bg-yellow-500 opacity-70"
          } text-black px-6 py-2 rounded-full w-full mt-2`}
        >
          {showOwnedPixels ? "Highlight My Pixels" : "Highlight My Pixels"}
        </button>
      </div>
    </div>
  );
};

export default Controls;

import { ethers } from 'ethers';
import BaseLotsABI from '../contracts/BaseLots_ABI.json';
import { PixelData } from '../types/types';

const CONTRACT_ADDRESS = process.env.REACT_APP_BASELOTS_CONTRACT;
const provider = new ethers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
const contract = new ethers.Contract(CONTRACT_ADDRESS as `0x${string}`, BaseLotsABI, provider);

async function uploadMetadata(
  tokenIds: number[],
  colors: string[],
  rows: number[],
  columns: number[],
  signature?: string,
  colorPunks?: (string | null)[],
  colorPunkImageUrl?: string | null,
  owner?: string
) {
  const payload: any = { tokenIds, colors, rows, columns, colorPunks, colorPunkImageUrl };

  if (signature) {
    payload.signature = signature;
  } else if (owner) {
    payload.owner = owner;
  }

  console.log('[Frontend] Sending payload:', JSON.stringify(payload, null, 2));

  try {
    const response = await fetch('https://my-pixel-app-d184ca3a228b.herokuapp.com/upload-metadata', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    console.log('[Frontend] Response status:', response.status);
    const responseText = await response.text();
    console.log('[Frontend] Response body:', responseText);

    if (!response.ok) {
      throw new Error(`Error uploading metadata: ${response.statusText}`);
    }

    const result = JSON.parse(responseText);
    console.log(`[Frontend] Metadata uploaded for tokens ${tokenIds}`, result);
  } catch (error) {
    console.error(`[Frontend] Error uploading metadata for tokens ${tokenIds}:`, error);
  }
}

export function listenForMintEvents() {
  console.log("Setting up listener for PixelMinted events");

  contract.on("PixelMinted", async (owner, tokenId, row, column) => {
    console.log(`PixelMinted event received: Token ${tokenId}, Row ${row}, Column ${column}`);

    // Pass all required parameters, including the owner address and generated image URL, skip the signature
    await uploadMetadata([tokenId], ["#FFFFFF"], [row], [column], undefined, [null], null, owner);
  });

  console.log("PixelMinted listener attached successfully");
}

// Ensure that we are not re-exporting types incorrectly
export { contract, uploadMetadata };
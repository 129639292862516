import React, { useState } from "react";
import Controls from "./Controls";

interface SidebarProps {
  zoomIn: () => void;
  zoomOut: () => void;
  zoomLevel: number;
  maxZoomLevel: number;
  clearSelection: () => void;
  saveChanges: () => void;
  selectPreset: (size: number) => void;
  setSelectedColor: (color: string) => void;
  onSelectColorPunk: (tokenId: string, imageUrl: string) => void;
  selectedPixels: number[];
  selectedColor: string;
  showOwnedPixels: boolean;
  setShowOwnedPixels: React.Dispatch<React.SetStateAction<boolean>>;
  ownedAndSelectedForUpdates: number[];
  setOwnedAndSelectedForUpdates: React.Dispatch<React.SetStateAction<number[]>>;
  dropColorPunk: () => void;
  selectedColorPunk: { tokenId: string; imageUrl: string } | null; // Add this prop
  setSelectedColorPunk: React.Dispatch<
    React.SetStateAction<{ tokenId: string; imageUrl: string } | null>
  >; // Add this prop
  is5x5Selected: boolean;
  onPurchaseComplete: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({
  zoomIn,
  zoomOut,
  zoomLevel,
  maxZoomLevel,
  clearSelection,
  saveChanges,
  selectPreset,
  setSelectedColor,
  onSelectColorPunk,
  selectedPixels,
  selectedColor,
  showOwnedPixels,
  setShowOwnedPixels,
  ownedAndSelectedForUpdates,
  setOwnedAndSelectedForUpdates,
  dropColorPunk,
  selectedColorPunk, // Destructure this
  setSelectedColorPunk, // Destructure this
  is5x5Selected,
  onPurchaseComplete,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const isDropColorPunkEnabled = is5x5Selected; // Enable if at least 5x5 owned pixels are selected

  return (
    <div
      className={`h-full text-white shadow-lg p-4 duration-300 ${
        isOpen ? "" : "w-16"
      } sm:relative sm:right-0 sm:flex sm:flex-col sm:h-full ${
        isOpen ? "xs:w-full lg:w-64" : "sm:w-16"
      }`}
    >
      {isOpen && (
        <div className="flex flex-col h-full">
          <Controls
            zoomIn={zoomIn}
            zoomOut={zoomOut}
            zoomLevel={zoomLevel}
            maxZoomLevel={maxZoomLevel}
            clearSelection={clearSelection}
            saveChanges={saveChanges} // Pass saveChanges prop
            selectPreset={selectPreset}
            setSelectedColor={setSelectedColor}
            onSelectColorPunk={onSelectColorPunk}
            selectedColor={selectedColor}
            showOwnedPixels={showOwnedPixels}
            setShowOwnedPixels={setShowOwnedPixels}
            ownedAndSelectedForUpdates={ownedAndSelectedForUpdates}
            setOwnedAndSelectedForUpdates={setOwnedAndSelectedForUpdates}
            selectedColorPunk={selectedColorPunk} // Pass selectedColorPunk prop
            setSelectedColorPunk={setSelectedColorPunk} // Pass setSelectedColorPunk prop
            is5x5Selected={is5x5Selected}
            selectedPixels={selectedPixels}
            onPurchaseComplete={onPurchaseComplete}
          />
        </div>
      )}
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import {
  useWriteContract,
  useSimulateContract,
  useWaitForTransactionReceipt,
} from "wagmi";
import { parseEther } from "viem";
import BaseLotsABI from "../contracts/BaseLots_ABI.json";
import { uploadMetadata } from "../utils/metadataUploader";
import { PixelPurchaseProps } from "../types/types";
import { useAccount } from "wagmi";
import PurchaseSuccessModal from "./PurchaseSuccessModal";

const PRICE_PER_PIXEL = 0.00001;
const CONTRACT_ADDRESS = process.env.REACT_APP_BASELOTS_CONTRACT;

const PixelPurchase: React.FC<PixelPurchaseProps> = ({
  selectedPixels,
  onPurchaseComplete,
}) => {
  const [totalCost, setTotalCost] = useState<string>("0");
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [purchasedTokenIds, setPurchasedTokenIds] = useState<number[]>([]);
  const { address } = useAccount();

  useEffect(() => {
    const cost = (selectedPixels.length * PRICE_PER_PIXEL).toFixed(5);
    if (cost !== totalCost) {
      setTotalCost(cost);
    }
  }, [selectedPixels, totalCost]);

  const { data: simulateData } = useSimulateContract({
    address: CONTRACT_ADDRESS as `0x${string}`,
    abi: BaseLotsABI,
    functionName: "mintBatch",
    args: [
      selectedPixels.map((pixel) => Math.floor(pixel / 1000) + 1),
      selectedPixels.map((pixel) => (pixel % 1000) + 1),
    ],
    value: parseEther(totalCost),
  });

  const { data: writeData, writeContract } = useWriteContract();
  const { isLoading, isSuccess, isError, data } = useWaitForTransactionReceipt({
    hash: writeData as `0x${string}`,
  });

  useEffect(() => {
    if (isSuccess && !isLoading) {
      console.log("Transaction successful, uploading metadata");

      const tokenIds: number[] = [];
      const uploadPromises = selectedPixels.map(async (pixel) => {
        const tokenId = pixel + 1;
        tokenIds.push(tokenId);
        const row = Math.floor((tokenId - 1) / 1000) + 1;
        const column = ((tokenId - 1) % 1000) + 1;

        console.log(
          `Uploading metadata for token ${tokenId}, row ${row}, column ${column}`
        );

        return uploadMetadata(
          [tokenId],
          ["#FFFFFF"],
          [row],
          [column],
          undefined,
          [null],
          null, // colorPunkImageUrl
          address
        );
      });

      Promise.all(uploadPromises).then(() => {
        console.log("All metadata uploaded successfully");
        setPurchasedTokenIds(tokenIds);
        setShowSuccessModal(true);
      });
    }

    if (isError) {
      console.error("Error with transaction:", data);
    }
  }, [isSuccess, isLoading, isError, selectedPixels, address]);

  const handleMintBatch = () => {
    const tx = writeContract({
      abi: BaseLotsABI,
      address: CONTRACT_ADDRESS as `0x${string}`,
      functionName: "mintBatch",
      args: [
        selectedPixels.map((pixel) => Math.floor(pixel / 1000) + 1),
        selectedPixels.map((pixel) => (pixel % 1000) + 1),
      ],
      value: parseEther(totalCost),
    });
  };

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div>
      <button
        onClick={handleMintBatch}
        disabled={!writeContract || isLoading || selectedPixels.length === 0}
        className={`px-6 py-2 rounded-full w-full ${
          selectedPixels.length === 0 ? "bg-gray-500" : "bg-yellow-500"
        } text-black`}
      >
        {isLoading
          ? "Purchasing..."
          : `Buy Pixels (${selectedPixels.length} pixels, ${totalCost} ETH)`}
      </button>
      {showSuccessModal && (
        <PurchaseSuccessModal
          purchasedTokenIds={purchasedTokenIds}
          onReload={handleReload}
          userAddress={address}
          transactionHash={writeData as string}
        />
      )}
    </div>
  );
};

export default PixelPurchase;

import React, { useEffect, useRef, useState } from "react";
import { PixelData } from "../types/types";
import { debounce } from "lodash";

const Canvas: React.FC<{
  zoomLevel: number;
  selectedPixels: number[];
  setSelectedPixels: React.Dispatch<React.SetStateAction<number[]>>;
  purchasedPixels: PixelData[];
  setPurchasedPixels: React.Dispatch<React.SetStateAction<PixelData[]>>;
  ownedPixels: number[];
  baseGridSize: number;
  zoomSteps: number[];
  selectedColor: string;
  showOwnedPixels: boolean;
  ownedAndSelectedForUpdates: number[];
  setOwnedAndSelectedForUpdates: React.Dispatch<React.SetStateAction<number[]>>;
  selectedColorPunk: { tokenId: string; imageUrl: string } | null;
  onColorPunkSelect: (tokenId: string, imageUrl: string) => void;
  is5x5Selected: boolean;
}> = ({
  zoomLevel,
  selectedPixels,
  setSelectedPixels,
  purchasedPixels,
  setPurchasedPixels,
  ownedPixels,
  baseGridSize,
  zoomSteps,
  selectedColor,
  showOwnedPixels,
  ownedAndSelectedForUpdates,
  setOwnedAndSelectedForUpdates,
  selectedColorPunk,
  onColorPunkSelect,
  is5x5Selected,
}) => {
  const size = Math.floor(baseGridSize / zoomSteps[zoomLevel]);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const fixedCanvasSize = 800;
  const defaultSquareSize = 20;
  const zoomLevel0SquareSize = 40;
  const squareSize = zoomLevel === 0 ? zoomLevel0SquareSize : defaultSquareSize;
  const actualCanvasSize = size * squareSize;

  const togglePixelSelection = (x: number, y: number) => {
    const index = y * size + x;
    const baseIndex = convertToBaseIndex(index, zoomLevel);
    const pixelsPerSquare = zoomSteps[zoomLevel] ** 2;

    if (isSquareOwned(index)) {
      setOwnedAndSelectedForUpdates((prev: number[]) => {
        const newSelection = [...prev];
        for (let i = 0; i < pixelsPerSquare; i++) {
          const pixelIndex =
            baseIndex +
            Math.floor(i / zoomSteps[zoomLevel]) * baseGridSize +
            (i % zoomSteps[zoomLevel]);
          const indexInSelection = newSelection.indexOf(pixelIndex + 1);
          if (indexInSelection === -1) {
            newSelection.push(pixelIndex + 1);
          } else {
            newSelection.splice(indexInSelection, 1);
          }
        }
        console.log("Updated ownedAndSelectedForUpdates:", newSelection);
        return newSelection;
      });
    } else if (!isSquarePurchased(index)) {
      setSelectedPixels((prev: number[]) => {
        const newSelection = [...prev];
        for (let i = 0; i < pixelsPerSquare; i++) {
          const pixelIndex =
            baseIndex +
            Math.floor(i / zoomSteps[zoomLevel]) * baseGridSize +
            (i % zoomSteps[zoomLevel]);
          const indexInSelection = newSelection.indexOf(pixelIndex);
          if (indexInSelection === -1) {
            newSelection.push(pixelIndex);
          } else {
            newSelection.splice(indexInSelection, 1);
          }
        }
        console.log("Updated selectedPixels:", newSelection);
        return newSelection;
      });
    } else {
      alert("This pixel is already purchased and cannot be selected.");
    }
    renderVisibleCanvas();
  };

  const convertToBaseIndex = (index: number, level: number) => {
    const factor = zoomSteps[level];
    const row = Math.floor(index / size) * factor;
    const col = (index % size) * factor;
    return row * baseGridSize + col;
  };

  const isSquareSelectedForUpdate = (index: number) => {
    const baseIndex = convertToBaseIndex(index, zoomLevel);
    const pixelsPerSquare = zoomSteps[zoomLevel] ** 2;

    for (let i = 0; i < pixelsPerSquare; i++) {
      const pixelIndex =
        baseIndex +
        Math.floor(i / zoomSteps[zoomLevel]) * baseGridSize +
        (i % zoomSteps[zoomLevel]);
      if (ownedAndSelectedForUpdates.includes(pixelIndex + 1)) {
        return true;
      }
    }
    return false;
  };

  const isSquareSelected = (index: number) => {
    const baseIndex = convertToBaseIndex(index, zoomLevel);
    const pixelsPerSquare = zoomSteps[zoomLevel] ** 2;

    for (let i = 0; i < pixelsPerSquare; i++) {
      const pixelIndex =
        baseIndex +
        Math.floor(i / zoomSteps[zoomLevel]) * baseGridSize +
        (i % zoomSteps[zoomLevel]);
      if (selectedPixels.includes(pixelIndex)) {
        return true;
      }
    }
    return false;
  };

  const isSquarePurchased = (index: number) => {
    const baseIndex = convertToBaseIndex(index, zoomLevel);
    const pixelsPerSquare = zoomSteps[zoomLevel] ** 2;

    for (let i = 0; i < pixelsPerSquare; i++) {
      const pixelIndex =
        baseIndex +
        Math.floor(i / zoomSteps[zoomLevel]) * baseGridSize +
        (i % zoomSteps[zoomLevel]);
      if (purchasedPixels.some((pixel) => pixel.id === pixelIndex + 1)) {
        return true;
      }
    }
    return false;
  };

  const isSquareOwned = (index: number) => {
    const baseIndex = convertToBaseIndex(index, zoomLevel);
    const pixelsPerSquare = zoomSteps[zoomLevel] ** 2;

    for (let i = 0; i < pixelsPerSquare; i++) {
      const pixelIndex =
        baseIndex +
        Math.floor(i / zoomSteps[zoomLevel]) * baseGridSize +
        (i % zoomSteps[zoomLevel]);
      if (ownedPixels.includes(pixelIndex + 1)) {
        return true;
      }
    }
    return false;
  };

  const renderVisibleCanvas = async () => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = canvas.width;
    offscreenCanvas.height = canvas.height;
    const offscreenCtx = offscreenCanvas.getContext("2d");
    if (!offscreenCtx) return;

    const scrollLeft = container.scrollLeft;
    const scrollTop = container.scrollTop;
    const startX = Math.floor(scrollLeft / squareSize);
    const startY = Math.floor(scrollTop / squareSize);
    const endX = Math.min(
      size,
      startX + Math.ceil(fixedCanvasSize / squareSize)
    );
    const endY = Math.min(
      size,
      startY + Math.ceil(fixedCanvasSize / squareSize)
    );

    offscreenCtx.clearRect(0, 0, offscreenCanvas.width, offscreenCanvas.height);

    for (let y = startY; y < endY; y++) {
      for (let x = startX; x < endX; x++) {
        const index = y * size + x;
        const baseIndex = convertToBaseIndex(index, zoomLevel);
        const pixelsPerSquare = zoomSteps[zoomLevel] ** 2;

        let color = "#6B7280"; // Default color
        let colorPunk = false;
        let colorPunkImageUrl = "";

        for (let i = 0; i < pixelsPerSquare; i++) {
          const pixelIndex =
            baseIndex +
            Math.floor(i / zoomSteps[zoomLevel]) * baseGridSize +
            (i % zoomSteps[zoomLevel]);
          const pixel = purchasedPixels.find((p) => p.id === pixelIndex + 1);
          if (pixel) {
            color = pixel.color;
            colorPunk = pixel.colorPunk || false;
            colorPunkImageUrl = pixel.imageUrl || "";
            break;
          }
        }

        if (colorPunk && colorPunkImageUrl) {
          try {
            const img = await preloadImage(colorPunkImageUrl);
            const imgX = (x - startX) * squareSize;
            const imgY = (y - startY) * squareSize;
            const imgWidth = squareSize;
            const imgHeight = squareSize;
            offscreenCtx.drawImage(img, imgX, imgY, imgWidth, imgHeight);
          } catch (error) {
            console.error("Failed to load image:", colorPunkImageUrl, error);
          }
        } else {
          offscreenCtx.fillStyle = color;
          offscreenCtx.globalAlpha =
            showOwnedPixels && !isSquareOwned(index) ? 0.1 : 1;
          offscreenCtx.fillRect(
            (x - startX) * squareSize,
            (y - startY) * squareSize,
            squareSize - 1,
            squareSize - 1
          );
          offscreenCtx.globalAlpha = 1;
        }

        if (isSquareSelected(index)) {
          offscreenCtx.strokeStyle = "#F59E0B"; // Orange border for selected pixels for purchase
          offscreenCtx.lineWidth = 2;
          offscreenCtx.strokeRect(
            (x - startX) * squareSize,
            (y - startY) * squareSize,
            squareSize - 1,
            squareSize - 1
          );
        }

        if (isSquareSelectedForUpdate(index)) {
          offscreenCtx.strokeStyle = "red"; // Red border for selected pixels for update
          offscreenCtx.lineWidth = 2;
          offscreenCtx.strokeRect(
            (x - startX) * squareSize,
            (y - startY) * squareSize,
            squareSize - 1,
            squareSize - 1
          );
        }
      }
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(offscreenCanvas, 0, 0);

    // Render the selected Color Punk image over the 5x5 grid
    if (selectedColorPunk && is5x5Selected) {
      const img = new Image();
      img.src = selectedColorPunk.imageUrl;

      img.onload = () => {
        const startX =
          Math.floor((ownedAndSelectedForUpdates[0] - 1) % baseGridSize) *
          squareSize;
        const startY =
          Math.floor((ownedAndSelectedForUpdates[0] - 1) / baseGridSize) *
          squareSize;
        const imgWidth = squareSize * 5;
        const imgHeight = squareSize * 5;

        ctx.drawImage(img, startX, startY, imgWidth, imgHeight);
      };

      img.onerror = (error) => {
        console.error(
          "Failed to load image:",
          selectedColorPunk.imageUrl,
          error
        );
      };
    }
  };

  const imageCache: { [key: string]: HTMLImageElement } = {};

  const preloadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      if (imageCache[src]) {
        resolve(imageCache[src]);
      } else {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          imageCache[src] = img;
          resolve(img);
        };
        img.onerror = reject;
      }
    });
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    canvas.width = fixedCanvasSize;
    canvas.height = fixedCanvasSize;

    renderVisibleCanvas();

    const handleScroll = debounce(() => {
      renderVisibleCanvas();
    }, 100);

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [
    zoomLevel,
    selectedPixels,
    purchasedPixels,
    ownedPixels,
    ownedAndSelectedForUpdates,
    size,
    showOwnedPixels,
    selectedColorPunk,
  ]);

  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    const rect = canvas.getBoundingClientRect();
    const x = Math.floor(
      (event.clientX - rect.left + container.scrollLeft) / squareSize
    );
    const y = Math.floor(
      (event.clientY - rect.top + container.scrollTop) / squareSize
    );

    const index = y * size + x;
    if (isSquareOwned(index)) {
      togglePixelSelection(x, y);
      renderVisibleCanvas();
    } else if (!isSquarePurchased(index)) {
      togglePixelSelection(x, y);
      renderVisibleCanvas();
    } else {
      alert("This pixel is already purchased and cannot be selected.");
    }
  };

  useEffect(() => {
    if (selectedColor) {
      setPurchasedPixels((prevPixels: PixelData[]) => {
        const updatedPixels = [...prevPixels];
        ownedAndSelectedForUpdates.forEach((pixelId) => {
          const pixel = updatedPixels.find((p) => p.id === pixelId);
          if (pixel) {
            pixel.color = selectedColor;
          }
        });
        return updatedPixels;
      });
      renderVisibleCanvas();
    }
  }, [selectedColor]);

  const zoomPercentage = (zoomSteps[0] / zoomSteps[zoomLevel]) * 100;
  const pixelsPerSquare = zoomSteps[zoomLevel] ** 2;

  const totalSelectedPixels = selectedPixels.length;

  return (
    <div className="flex-1">
      <div className="p-4">
        <h2 className="text-lg">Selected Pixels: {totalSelectedPixels}</h2>
        {/* <p className="text-sm">
          Zoom Level: {zoomPercentage.toFixed(2)}% | 1 square ={" "}
          {pixelsPerSquare} pixels
        </p> */}
      </div>
      <div
        ref={containerRef}
        className="overflow-auto bg-gray-800"
        style={{
          width: `${fixedCanvasSize}px`,
          height: `${fixedCanvasSize}px`,
        }}
      >
        <div
          style={{
            width: `${actualCanvasSize}px`,
            height: `${actualCanvasSize}px`,
            position: "relative",
          }}
        >
          <canvas
            ref={canvasRef}
            onClick={handleCanvasClick}
            style={{
              cursor: "pointer",
              position: "sticky",
              top: 0,
              left: 0,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Canvas;

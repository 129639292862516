import React, { useState, useEffect } from "react";
import { Alchemy, Network } from "alchemy-sdk";
import { useAccount } from "wagmi";
import { ColorPunk, ColorPunkTrayProps } from "../types/types";

const ColorPunkTray: React.FC<ColorPunkTrayProps> = ({
  isOpen,
  onClose,
  onSelectPunk,
  selectedColorPunk,
  setSelectedColorPunk,
}) => {
  const [punks, setPunks] = useState<ColorPunk[]>([]);
  const { address } = useAccount();

  useEffect(() => {
    const fetchColorPunks = async () => {
      if (!address) return;

      const settings = {
        apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
        network: Network.BASE_MAINNET,
      };
      const alchemy = new Alchemy(settings);
      const contractAddress = "0x67c7187031819a83e86286a64d117305b1902eb2";

      try {
        const nftsForOwner = await alchemy.nft.getNftsForOwner(address, {
          contractAddresses: [contractAddress],
        });
        const colorPunks = nftsForOwner.ownedNfts
          .filter((nft) => nft.image?.thumbnailUrl !== undefined)
          .map((nft) => ({
            tokenId: nft.tokenId,
            imageUrl: nft.image?.cachedUrl || "",
          }));
        console.log(
          "colorPunks",
          colorPunks.map((punk) => punk.imageUrl)
        );
        setPunks(colorPunks);
      } catch (error) {
        console.error("Error fetching Color Punks:", error);
      }
    };

    if (isOpen && address) {
      fetchColorPunks();
    }
  }, [isOpen, address]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-4 rounded-lg">
        <h2 className="text-xl mb-4">Select a Color Punk</h2>
        <div className="grid grid-cols-5 gap-2">
          {punks.map((punk) => (
            <div
              key={punk.tokenId}
              className="w-12 h-12 cursor-pointer"
              onClick={() => {
                onSelectPunk(punk.tokenId, punk.imageUrl);
                console.log("punk", punk.tokenId, punk.imageUrl);
                onClose();
              }}
            >
              <img
                src={punk.imageUrl}
                alt={`Color Punk #${punk.tokenId}`}
                className="w-full h-full object-cover rounded"
              />
            </div>
          ))}
        </div>
        {punks.length === 0 && (
          <p className="text-white">No Color Punks found for this address.</p>
        )}
        <button
          className="mt-4 bg-yellow-500 text-black px-4 py-2 rounded"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ColorPunkTray;

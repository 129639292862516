import React, { FC, useState, useEffect, useCallback, useRef } from "react"; // Import FC
import {
  Alchemy,
  Network,
  Nft,
  NftContractNftsResponse,
  OwnedNftsResponse,
} from "alchemy-sdk"; // Import necessary types
import Header from "./components/Header";
import Canvas from "./components/Canvas";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import ColorTray from "./components/ColorTray"; // Import ColorTray
import ColorPunkTray from "./components/ColorPunkTray"; // Import ColorPunkTray
import {
  contract,
  listenForMintEvents,
  uploadMetadata,
} from "./utils/metadataUploader";
import { PixelData } from "./types/types";
import { useAccount, useSignMessage } from "wagmi"; // Import useAccount and useSignMessage from wagmi
import axios from "axios";
import { debounce } from "lodash";
import PixelPurchase from "./components/PixelPurchase";

// Define a type for the response
interface NftWithMetadata extends Nft {
  metadata: {
    attributes: { trait_type: string; value: string }[];
  };
}

interface CustomNftContractNftsResponse extends NftContractNftsResponse {
  nfts: NftWithMetadata[];
}

const CONTRACT_ADDRESS = process.env
  .REACT_APP_BASELOTS_CONTRACT as `0x${string}`;

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.BASE_MAINNET,
};
const alchemy = new Alchemy(settings);

const App: FC = () => {
  // Use FC type for the component
  const { address } = useAccount(); // Get the user's wallet address
  const [zoomLevel, setZoomLevel] = useState(5);
  const [selectedPixels, setSelectedPixels] = useState<number[]>([]);
  const [purchasedPixels, setPurchasedPixels] = useState<PixelData[]>([]);
  const [ownedPixels, setOwnedPixels] = useState<number[]>([]); // State to store owned pixels
  const [selectedColor, setSelectedColor] = useState<string>(""); // State for selected color
  const [isColorTrayOpen, setIsColorTrayOpen] = useState<boolean>(false); // State for ColorTray visibility
  const [showOwnedPixels, setShowOwnedPixels] = useState<boolean>(false); // State for showing owned pixels
  const [ownedAndSelectedForUpdates, setOwnedAndSelectedForUpdates] = useState<
    number[]
  >([]);
  const { signMessageAsync } = useSignMessage();
  const [isLoading, setIsLoading] = useState<boolean>(true); // State for loading spinner
  const [selectedColorPunk, setSelectedColorPunk] = useState<{
    tokenId: string;
    imageUrl: string;
  } | null>(null);
  // State for selected Color Punk
  const [isColorPunkTrayOpen, setIsColorPunkTrayOpen] =
    useState<boolean>(false); // State for ColorPunkTray visibility
  const [is5x5Selected, setIs5x5Selected] = useState(false);

  const maxZoomLevel = 5;
  const baseGridSize = 1000;
  const zoomSteps = [40, 20, 10, 5, 2, 1]; // 6 zoom levels
  const size = Math.floor(baseGridSize / zoomSteps[zoomLevel]);

  const check5x5Selection = (selectedPixels: number[]) => {
    if (selectedPixels.length < 25) return false;

    const rows = selectedPixels.map((id) => Math.floor(id / baseGridSize));
    const cols = selectedPixels.map((id) => id % baseGridSize);

    const minRow = Math.min(...rows);
    const maxRow = Math.max(...rows);
    const minCol = Math.min(...cols);
    const maxCol = Math.max(...cols);

    return (
      maxRow - minRow === 4 &&
      maxCol - minCol === 4 &&
      selectedPixels.every((id) => ownedPixels.includes(id))
    );
  };

  useEffect(() => {
    const isSelected = check5x5Selection(ownedAndSelectedForUpdates);
    console.log("is5x5Selected:", isSelected);
    setIs5x5Selected(isSelected);
  }, [ownedAndSelectedForUpdates, ownedPixels]);

  const refreshOwnedPixels = useCallback(async () => {
    if (!address) {
      console.log("No address available");
      return;
    }

    setIsLoading(true);
    try {
      let allOwnedNfts: OwnedNftsResponse["ownedNfts"] = [];
      let pageKey: string | undefined = undefined;

      do {
        const response: OwnedNftsResponse = await alchemy.nft.getNftsForOwner(
          address,
          {
            contractAddresses: [CONTRACT_ADDRESS],
            pageKey: pageKey,
            pageSize: 100, // Max page size
          }
        );

        console.log("Alchemy response:", response);

        allOwnedNfts = [...allOwnedNfts, ...response.ownedNfts];
        pageKey = response.pageKey;
      } while (pageKey);

      const ownedTokenIds = allOwnedNfts.map((nft) => Number(nft.tokenId));
      console.log("All owned token IDs:", ownedTokenIds);
      console.log("Total owned NFTs:", ownedTokenIds.length);

      setOwnedPixels(ownedTokenIds);

      // Update purchasedPixels state with the owned pixels
      setPurchasedPixels((prevPixels) => {
        const updatedPixels = [...prevPixels];
        ownedTokenIds.forEach((tokenId) => {
          const existingPixelIndex = updatedPixels.findIndex(
            (p) => p.id === tokenId
          );
          if (existingPixelIndex === -1) {
            updatedPixels.push({
              id: tokenId,
              color: "#FFFFFF",
              colorPunk: false,
            });
          }
        });
        return updatedPixels;
      });
    } catch (error) {
      console.error("Error refreshing owned pixels:", error);
    } finally {
      setIsLoading(false);
    }
  }, [address]);
  // Fetch metadata for multiple pixels in parallel (batching requests)
  const fetchMetadataForPixels = async (tokenIds: number[]) => {
    // Use Promise.all to batch the fetch requests
    return await Promise.all(
      tokenIds.map(async (tokenId) => {
        const s3Url = `https://basedlots.s3.us-east-2.amazonaws.com/pixels/${tokenId}.json?${new Date().getTime()}`;
        try {
          const response = await fetch(s3Url);
          if (!response.ok) return null; // Skip if metadata is not found

          const metadata = await response.json();

          const colorAttribute = metadata.attributes?.find(
            (attr: { trait_type: string; value: string }) =>
              attr.trait_type === "Color"
          );
          const colorPunkAttribute = metadata.attributes?.find(
            (attr: { trait_type: string; value: string }) =>
              attr.trait_type === "Color Punk"
          );
          const colorPunkIdAttribute = metadata.attributes?.find(
            (attr: { trait_type: string; value: string }) =>
              attr.trait_type === "Color Punk ID"
          );

          const pixelData: PixelData = {
            id: tokenId,
            color: colorAttribute ? colorAttribute.value : "#FFFFFF",
            colorPunk: colorPunkAttribute
              ? colorPunkAttribute.value === "True"
              : false,
            colorPunkId: colorPunkIdAttribute
              ? colorPunkIdAttribute.value
              : undefined,
            imageUrl:
              colorPunkAttribute && colorPunkAttribute.value === "True"
                ? metadata.image
                : undefined,
          };

          return pixelData;
        } catch (fetchError) {
          console.error(
            `Error fetching metadata for token ID ${tokenId}:`,
            fetchError
          );
          return null; // Return null if there was an error
        }
      })
    );
  };

  // Main function that will fetch all pixels in batches
  useEffect(() => {
    const fetchAllPixels = async () => {
      let allPixels: PixelData[] = [];
      let pageKey: string | undefined = undefined;

      do {
        try {
          const response = (await alchemy.nft.getNftsForContract(
            CONTRACT_ADDRESS,
            {
              pageKey: pageKey,
              pageSize: 100, // Maximum page size
            }
          )) as CustomNftContractNftsResponse;

          console.log("Raw response from Alchemy:", response);

          const tokenIds = response.nfts.map((nft) => Number(nft.tokenId));

          // Batch fetching the metadata for the token IDs
          const fetchedPixels = await fetchMetadataForPixels(tokenIds);

          // Filter out any null values in case any fetches failed
          const validPixels = fetchedPixels.filter(
            (pixel) => pixel !== null
          ) as PixelData[];

          allPixels = [...allPixels, ...validPixels];

          // Update the state incrementally
          setPurchasedPixels((prevPixels) => [...prevPixels, ...validPixels]);

          pageKey = response.pageKey;
        } catch (error) {
          console.error("Error fetching all pixels:", error);
          break;
        }
      } while (pageKey);

      setIsLoading(false); // Set loading to false once all pixels are fetched
    };

    fetchAllPixels();
  }, []);

  const fetchOwnedPixels = useCallback(
    async (retryCount = 3) => {
      if (!address) return;

      try {
        let allOwnedNfts: OwnedNftsResponse["ownedNfts"] = [];
        let pageKey: string | undefined = undefined;

        do {
          const response: OwnedNftsResponse = await alchemy.nft.getNftsForOwner(
            address,
            {
              contractAddresses: [CONTRACT_ADDRESS],
              pageKey: pageKey,
              pageSize: 100, // Max page size
            }
          );

          allOwnedNfts = [...allOwnedNfts, ...response.ownedNfts];
          pageKey = response.pageKey;
        } while (pageKey);

        const ownedTokenIds = allOwnedNfts.map((nft) => Number(nft.tokenId));
        console.log("All owned token IDs:", ownedTokenIds);
        console.log("Total owned NFTs:", ownedTokenIds.length);

        setOwnedPixels((prevOwnedPixels) => {
          if (
            prevOwnedPixels.length === ownedTokenIds.length &&
            prevOwnedPixels.every((id, index) => id === ownedTokenIds[index])
          ) {
            return prevOwnedPixels; // Avoid unnecessary state update
          }
          return ownedTokenIds;
        });

        // Fetch metadata and update purchasedPixels with correct data
        const updatedPixels = await Promise.all(
          ownedTokenIds.map(async (tokenId) => {
            const s3Url = `https://basedlots.s3.us-east-2.amazonaws.com/pixels/${tokenId}.json?${new Date().getTime()}`;
            try {
              const metadataResponse = await fetch(s3Url);
              if (!metadataResponse.ok) {
                console.warn(`Metadata file not found for token ID ${tokenId}`);
                return { id: tokenId, color: "#FFFFFF", colorPunk: false };
              }
              const metadata = await metadataResponse.json();
              const colorAttribute = metadata.attributes?.find(
                (attr: { trait_type: string; value: string }) =>
                  attr.trait_type === "Color"
              );
              const colorPunkAttribute = metadata.attributes?.find(
                (attr: { trait_type: string; value: string }) =>
                  attr.trait_type === "Color Punk"
              );
              const colorPunkIdAttribute = metadata.attributes?.find(
                (attr: { trait_type: string; value: string }) =>
                  attr.trait_type === "Color Punk ID"
              );
              return {
                id: tokenId,
                color: colorAttribute ? colorAttribute.value : "#FFFFFF",
                colorPunk: colorPunkAttribute
                  ? colorPunkAttribute.value === "True"
                  : false,
                colorPunkId: colorPunkIdAttribute
                  ? colorPunkIdAttribute.value
                  : undefined,
                imageUrl:
                  colorPunkAttribute && colorPunkAttribute.value === "True"
                    ? metadata.image
                    : undefined,
              };
            } catch (error) {
              console.error(
                `Error fetching metadata for token ID ${tokenId}:`,
                error
              );
              return { id: tokenId, color: "#FFFFFF", colorPunk: false };
            }
          })
        );

        setPurchasedPixels((prevPixels) => {
          const newPixels = [...prevPixels];
          updatedPixels.forEach((pixel) => {
            const index = newPixels.findIndex((p) => p.id === pixel.id);
            if (index !== -1) {
              newPixels[index] = pixel;
            } else {
              newPixels.push(pixel);
            }
          });
          return newPixels;
        });
      } catch (error) {
        console.error("Error fetching owned pixels:", error);

        if (retryCount > 0) {
          console.log(`Retrying... (${3 - retryCount + 1})`);
          setTimeout(
            () => fetchOwnedPixels(retryCount - 1),
            1000 * (4 - retryCount)
          );
        }
      }
    },
    [address]
  );

  // Fetch the owned pixels for the connected address
  useEffect(() => {
    if (address) {
      fetchOwnedPixels();
    }
  }, [address, fetchOwnedPixels]);

  const findNextAvailableLot = (size: number) => {
    const gridSize = 1000;
    for (let row = 1; row <= gridSize - size + 1; row++) {
      for (let col = 1; col <= gridSize - size + 1; col++) {
        let available = true;
        for (let i = 0; i < size; i++) {
          for (let j = 0; j < size; j++) {
            const pixelId = (row + i - 1) * gridSize + (col + j - 1) + 1;
            if (purchasedPixels.some((pixel) => pixel.id === pixelId)) {
              available = false;
              break;
            }
          }
          if (!available) break;
        }
        if (available) return { row, col };
      }
    }
    return null;
  };

  const clearSelection = () => {
    setSelectedPixels([]);
    setOwnedAndSelectedForUpdates([]); // Clear ownedAndSelectedForUpdates pixels
  };

  const saveChanges = async () => {
    if (
      !Array.isArray(ownedAndSelectedForUpdates) ||
      ownedAndSelectedForUpdates.length === 0
    ) {
      console.error("No pixels selected for update");
      return;
    }

    const message = `I am updating my pixels: ${ownedAndSelectedForUpdates.join(
      ", "
    )}`;
    console.log("Message being signed:", message);
    try {
      const signature = await signMessageAsync({ message });
      console.log("Signature:", signature);

      const colors = ownedAndSelectedForUpdates.map((pixelId) => {
        const pixel = purchasedPixels.find((p) => p.id === pixelId);
        return pixel ? pixel.color : "";
      });

      const rows = ownedAndSelectedForUpdates.map(
        (pixelId) => Math.floor((pixelId - 1) / baseGridSize) + 1
      );
      const columns = ownedAndSelectedForUpdates.map(
        (pixelId) => ((pixelId - 1) % baseGridSize) + 1
      );

      const isColorPunkApplied =
        selectedColorPunk && ownedAndSelectedForUpdates.length === 25;

      const colorPunks = ownedAndSelectedForUpdates.map(() => {
        if (isColorPunkApplied) {
          return selectedColorPunk.tokenId;
        }
        return null;
      });

      const colorPunkImageUrl = isColorPunkApplied
        ? selectedColorPunk.imageUrl
        : null;

      console.log("Sending data to backend:", {
        tokenIds: ownedAndSelectedForUpdates,
        colors,
        rows,
        columns,
        signature,
        colorPunks,
        colorPunkImageUrl,
      });

      await uploadMetadata(
        ownedAndSelectedForUpdates,
        colors,
        rows,
        columns,
        signature,
        colorPunks,
        colorPunkImageUrl
      );

      // Clear the selections and refresh the view
      setOwnedAndSelectedForUpdates([]);
      setSelectedPixels([]);
      setSelectedColorPunk(null);

      console.log("Pixels updated successfully.");

      // Refresh the metadata by refetching it from S3
      await fetchOwnedPixels();
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const colorPixels = () => {
    setIsColorTrayOpen(true); // Open the ColorTray
  };

  const dropColorPunk = () => {
    setIsColorPunkTrayOpen(true); // Open the ColorPunkTray
  };

  const selectedPixelsRef = useRef<number[]>(selectedPixels);

  useEffect(() => {
    selectedPixelsRef.current = selectedPixels;
  }, [selectedPixels]);

  const handlePurchaseComplete = useCallback(() => {
    console.log("Purchase complete, updating state");

    setPurchasedPixels((prevPixels) => {
      const newPixels: PixelData[] = [
        ...prevPixels,
        ...selectedPixelsRef.current.map((pixel) => ({
          id: pixel + 1,
          color: "#FFFFFF", // Default color for newly purchased pixels
          colorPunk: false,
        })),
      ];
      console.log("Updated purchased pixels:", newPixels);
      return newPixels;
    });

    // Clear selected pixels
    setSelectedPixels([]);
    // setShowPurchaseUI(false); // Uncomment and adjust if you have this state
  }, [fetchOwnedPixels]);

  const handleColorPunkSelect = (tokenId: string, imageUrl: string) => {
    console.log("handleColorPunkSelect called with:", { tokenId, imageUrl });

    setSelectedColorPunk({ tokenId, imageUrl });

    if (ownedAndSelectedForUpdates.length >= 25) {
      const startIndex = ownedAndSelectedForUpdates[0];
      const startRow = Math.floor((startIndex - 1) / baseGridSize);
      const startCol = (startIndex - 1) % baseGridSize;

      setPurchasedPixels((prevPixels) => {
        return prevPixels.map((pixel) => {
          const pixelRow = Math.floor((pixel.id - 1) / baseGridSize);
          const pixelCol = (pixel.id - 1) % baseGridSize;

          if (
            pixelRow >= startRow &&
            pixelRow < startRow + 5 &&
            pixelCol >= startCol &&
            pixelCol < startCol + 5
          ) {
            console.log(`Updating pixel ${pixel.id} to have ColorPunk`);
            return {
              ...pixel,
              colorPunk: true,
              colorPunkId: tokenId,
              colorPunkPosition: {
                row: pixelRow - startRow,
                column: pixelCol - startCol,
              },
              colorPunkImageUrl: imageUrl,
            };
          }
          return pixel;
        });
      });

      setOwnedAndSelectedForUpdates([]);
    } else {
      console.error("Not enough pixels selected for Color Punk placement");
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header
        selectedPixels={selectedPixels}
        onPurchaseComplete={handlePurchaseComplete}
      />
      <main className="flex flex-col lg:flex-row flex-grow overflow-hidden">
        <div className="relative flex-grow">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
              <div className="loader">Loading...</div>
            </div>
          )}
          <Canvas
            zoomLevel={zoomLevel}
            selectedPixels={selectedPixels}
            setSelectedPixels={setSelectedPixels}
            purchasedPixels={purchasedPixels}
            setPurchasedPixels={setPurchasedPixels}
            ownedPixels={ownedPixels}
            baseGridSize={baseGridSize}
            zoomSteps={zoomSteps}
            selectedColor={selectedColor}
            showOwnedPixels={showOwnedPixels}
            ownedAndSelectedForUpdates={ownedAndSelectedForUpdates}
            setOwnedAndSelectedForUpdates={setOwnedAndSelectedForUpdates}
            onColorPunkSelect={handleColorPunkSelect}
            selectedColorPunk={selectedColorPunk}
            is5x5Selected={is5x5Selected}
          />

          {isColorTrayOpen && (
            <ColorTray
              isOpen={isColorTrayOpen}
              onClose={() => setIsColorTrayOpen(false)}
              setSelectedColor={setSelectedColor}
              selectedColor={selectedColor}
            />
          )}

          {isColorPunkTrayOpen && (
            <ColorPunkTray
              isOpen={isColorPunkTrayOpen}
              onClose={() => setIsColorPunkTrayOpen(false)}
              onSelectPunk={handleColorPunkSelect}
              selectedColorPunk={selectedColorPunk}
              setSelectedColorPunk={setSelectedColorPunk}
            />
          )}
        </div>
        <Sidebar
          zoomIn={() =>
            setZoomLevel((zoom) => Math.min(zoom + 1, maxZoomLevel))
          }
          zoomOut={() => setZoomLevel((zoom) => Math.max(zoom - 1, 0))}
          zoomLevel={zoomLevel}
          maxZoomLevel={maxZoomLevel}
          clearSelection={clearSelection}
          saveChanges={saveChanges}
          selectPreset={(size: number) => {
            const availableLot = findNextAvailableLot(size);
            if (availableLot) {
              const { row, col } = availableLot;
              const newSelection = [];
              for (let i = 0; i < size; i++) {
                for (let j = 0; j < size; j++) {
                  const pixelId =
                    (row + i - 1) * baseGridSize + (col + j - 1) + 1;
                  newSelection.push(pixelId);
                }
              }
              setSelectedPixels(newSelection);
            } else {
              console.error(`No available ${size}x${size} lot found`);
            }
          }}
          setSelectedColor={setSelectedColor}
          onSelectColorPunk={(tokenId: string, imageUrl: string) =>
            setSelectedColorPunk({ tokenId, imageUrl })
          }
          selectedColor={selectedColor}
          showOwnedPixels={showOwnedPixels}
          setShowOwnedPixels={setShowOwnedPixels}
          ownedAndSelectedForUpdates={ownedAndSelectedForUpdates}
          setOwnedAndSelectedForUpdates={setOwnedAndSelectedForUpdates}
          dropColorPunk={dropColorPunk}
          selectedColorPunk={selectedColorPunk}
          setSelectedColorPunk={setSelectedColorPunk}
          is5x5Selected={is5x5Selected}
          selectedPixels={selectedPixels}
          onPurchaseComplete={handlePurchaseComplete}
        />
      </main>
      <Footer />
    </div>
  );
};

export default App;

import React from "react";
import { PurchaseSuccessModalProps } from "../types/types";

const PurchaseSuccessModal: React.FC<PurchaseSuccessModalProps> = ({
  purchasedTokenIds,
  onReload,
  userAddress,
  transactionHash,
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-8 rounded-lg max-w-md w-full">
        <h2 className="text-2xl font-bold mb-4">Purchase Successful!</h2>
        <p className="mb-4">You have successfully purchased the following:</p>
        <div className="mb-6">
          <ul className="list-disc list-inside">
            {purchasedTokenIds.slice(0, 3).map((tokenId) => (
              <li key={tokenId}>Pixel: {tokenId}</li>
            ))}
          </ul>
          {purchasedTokenIds.length > 3 && (
            <div className="mt-2 max-h-40 overflow-y-auto">
              <ul className="list-disc list-inside">
                {purchasedTokenIds.slice(3).map((tokenId) => (
                  <li key={tokenId}>Pixel: {tokenId}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="flex flex-col space-y-4">
          <button
            onClick={onReload}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
          >
            Reload Page to Continue
          </button>
          <a
            href={`https://basescan.org/token/0x2825614269496906a10689f3de9c57597d3bf0b1?a=${userAddress}`}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-colors text-center"
          >
            View Transaction
          </a>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSuccessModal;
